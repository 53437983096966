<template>
  <div class="home">
    <div class="hello">
      <nav class="navbar custom-nav navbar-expand-lg navbar-light">
        <div class="container">
          <router-link class="navbar-brand" to="/">
            <img src="/img/logo.png" alt="Logo" class="logo-img">
          </router-link>
          <button class="navbar-toggler custom-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>

          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto"></ul>
            <form class="form-inline my-2 my-lg-0">
<!--              <a href="https://forms.gle/Fe5QmcZ8xbKKinVs5" class="btn custom-btn btn-outline-warning mr-5" target="_blank">-->
<!--                Inscreva-se para o curso de evangelismo ministério VIDA-->
<!--              </a>-->
              <a class="btn btn-outline-success my-2 my-sm-0" href="#" data-toggle="modal" data-target="#start">
                SisLife
              </a>
            </form>
          </div>
        </div>
      </nav>

      <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel" data-interval="5000" data-pause="hover">
<!--        <ol class="carousel-indicators">-->
<!--          <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>-->
<!--          <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>-->
<!--        </ol>-->
        <div class="carousel-inner">
          <div class="carousel-item active" data-video="https://www.youtube.com/embed/nOgZ3Y43PTQ?autoplay=1&mute=1&loop=1&playlist=nOgZ3Y43PTQ">
            <div class="hero">
              <div class="video-background">
                <iframe :src="currentVideo" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
              </div>
              <div class="overlay"></div>
              <div class="hero-content">
                <div class="container text-center text-light py-5">
                  <h2>PÓS ACAMP 29 de Março</h2>
                  <h4 class="mt-3">I ACAMP EXTREME ADVENTURE</h4>
<!--                  <p>"Bem-aventurado aquele que lê, e os que ouvem as palavras desta profecia, e guardam as coisas que nela estão escritas; porque o tempo está próximo." Ap 1:3</p>-->
<!--                  <ul class="list-unstyled mt-4">-->
<!--                    <li>✔️ Diversão garantida</li>-->
<!--                    <li>✔️ Atividades repletas de adrenalina</li>-->
<!--                    <li>✔️ Palavra e louvores a Deus</li>-->
<!--                  </ul>-->
<!--                  <div class="btn-group-vertical mt-3">-->
<!--&lt;!&ndash;                    <a href="https://forms.gle/tFSHUeZKgM8sUfXg9" class="btn custom-btn btn-outline-info mb-2" target="_blank">📥 Inscreva-se agora</a>&ndash;&gt;-->
<!--                    <a href="https://forms.gle/nfTmy12L2Hb5HKWh8" class="btn custom-btn btn-outline-success" target="_blank">Inscrever-se como visitante</a>-->
<!--                  </div>-->
                </div>
              </div>
            </div>
          </div>
<!--          <div class="carousel-item" data-video="https://www.youtube.com/embed/uHnokhfj-ek?autoplay=1&mute=1&loop=1&playlist=PL1MY4sQT3Oe0AKbtnK85FvQu1lYzcsW0-">-->
<!--            <div class="hero">-->
<!--              <div class="video-background">-->
<!--                <iframe :src="currentVideo" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>-->
<!--              </div>-->
<!--              <div class="overlay"></div>-->
<!--              <div class="hero-content">-->
<!--                <div class="container text-center text-light py-5">-->
<!--                  <h2>Curso</h2>-->
<!--                  <h4 class="mt-3">Evangelismo Ministério VIDA</h4>-->
<!--                  <p>Inscreva-se para o curso de evangelismo ministério VIDA</p>-->
<!--                  <a href="https://forms.gle/Fe5QmcZ8xbKKinVs5" class="btn custom-btn btn-outline-warning mt-3" target="_blank">Inscreva-se agora</a>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
        </div>
<!--        <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">-->
<!--          <span class="carousel-control-prev-icon" aria-hidden="true"></span>-->
<!--          <span class="sr-only">Previous</span>-->
<!--        </a>-->
<!--        <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">-->
<!--          <span class="carousel-control-next-icon" aria-hidden="true"></span>-->
<!--          <span class="sr-only">Next</span>-->
<!--        </a>-->
      </div>
      <Login />
    </div>
  </div>
</template>

<script>
import Login from "@/components/Login.vue";

export default {
  name: "home",
  components: {
    Login
  },
  data() {
    return {
      currentVideo: "https://www.youtube.com/embed/gI220wkAPfI?autoplay=1&mute=1&loop=1&playlist=gI220wkAPfI"
    };
  },
  mounted() {
    const carousel = document.querySelector('#carouselExampleIndicators');
    carousel.addEventListener('slid.bs.carousel', (event) => {
      const activeItem = event.target.querySelector('.carousel-item.active');
      this.currentVideo = activeItem.getAttribute('data-video');
    });
  }
};
</script>

<style scoped>
.navbar.custom-nav {
  background: transparent !important;
  z-index: 10;
  position: fixed;
  top: 0;
  width: 100%;
  padding: 1rem 2rem;
}

.navbar-brand .logo-img {
  height: 80px;
  width: auto;
  max-width: 100%;
}

.navbar-toggler.custom-toggler {
  border-color: white;
}

.custom-toggler {
  border: none;
  background: transparent;
}

.custom-toggler .navbar-toggler-icon {
  width: 30px;
  height: 3px;
  background-color: #000;
  display: block;
  position: relative;
}

.custom-toggler .navbar-toggler-icon::before,
.custom-toggler .navbar-toggler-icon::after {
  content: "";
  width: 30px;
  height: 3px;
  background-color: #FFFFFF;
  position: absolute;
  left: 0;
  transition: all 0.3s;
}

.custom-toggler .navbar-toggler-icon::before {
  top: -8px;
}

.custom-toggler .navbar-toggler-icon::after {
  top: 8px;
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  background-color: transparent;
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon::before {
  transform: rotate(45deg);
  top: 0;
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon::after {
  transform: rotate(-45deg);
  top: 0;
}

.video-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
  background-color: black;
}

.video-background iframe {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100vw;
  height: 56.25vw;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 0;
}

.hero-content {
  position: relative;
  z-index: 1;
  padding-top: 120px;
}

.hero-content h2,
.hero-content h4 {
  font-weight: bold;
}

.hero-content ul {
  list-style: none;
  padding: 0;
}

.hero-content li {
  margin: 10px 0;
  font-size: 18px;
}

.carousel-item {
  height: 100vh;
  min-height: 400px;
  background: transparent;
  color: white;
  position: relative;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: black;
  border-radius: 50%;
}

@media (max-width: 768px) {
  .navbar-collapse {
    background: rgba(0, 0, 0, 0.8);
  }

  .navbar-nav .nav-link, .navbar .btn {
    color: white !important;
  }
}
</style>
